import React from 'react'

import _ from 'lodash'
import moment from 'moment'

const HealthSnapPDF = (props) => {
    const { state } = props
    const { answers, healthSnap, qHash } = state

    let parseDataValue = (key) => {
        let dataValue = healthSnap.dataValues.find(o => o.key === key)

        return dataValue ? JSON.parse(dataValue.values[0]) : []
    }

    return (
        <div className='healthsnap-pdf-view full-screen-view'>
            <div className='pdf-header-frame'>
                <div className='header-label'>LifeWallet Intake Form</div>

                <div className='subheader-label'>Patient Information: Page 1 of 5</div>
            </div>

            <ul className='groups-list-view'>
                {Object.keys(qHash).map((key, i) => {
                    const { dob, firstName, lastName, latestData } = healthSnap.user
                    const { addresses, name } = healthSnap.organization

                    let section = qHash[key],
                        sectionTitle = section.conditions[0].value, 
                        gender = latestData.find(o => o.key === 'person.gender'),
                        address = addresses.find(o => o.label === 'main')

                    // console.log(sectionTitle)

                    if (sectionTitle === 'Personal Information') {
                        let dataValue = healthSnap.dataValues.find(o => o.key === 'person.emergency_contact'),
                            emergencyContacts = dataValue ? JSON.parse(dataValue.values[0]) : []

                        return (
                            <div className='section-break' key={i}>
                                <div className='section-frame row'>
                                    <div className='section-subframe'>
                                        <div className='section-header'>patient</div>
                                        <div className='section-label'>{`${firstName} ${lastName}`}</div>
                                        <div className='section-label'>{gender ? gender.data[0] : '-'}</div>
                                        <div className='section-label'>{`D.O.B: ${moment(dob).format('MMM D, YYYY')}`}</div>
                                    </div>

                                    <div className='section-subframe'>
                                        <div className='section-header'>facility</div>
                                        <div>{name}</div>
                                        <div>{address ? address.line1 : '-'}</div>
                                        <div>{address ? address.line2 : '-'}</div>
                                    </div>

                                    <div className='section-subframe'>
                                        <div className='section-header'>provider</div>
                                        <div>{name}</div>
                                        <div>{address ? address.line1 : '-'}</div>
                                        <div>{address ? address.line2 : '-'}</div>
                                    </div>
                                </div>

                                <div className='section-frame'>
                                    <div className='section-header'>patient information</div>


                                    <ul className='questions-list-view'>
                                        {section.questions.map(question => {
                                            let value = answers[question.key]

                                            return (
                                                <div className='question-cell' key={question.key}>
                                                    <div className='section-label'>{question.text}</div>
                                                    <div className='section-label bold'>{(question.kind === 'date') ? moment(value).format('MMM D, YYYY') : value}</div>
                                                </div>
                                            )
                                        })}
                                    </ul>
                                </div>

                                <div className='section-frame'>
                                    <div className='section-header'>{`emergency contacts (${emergencyContacts.length})`}</div>

                                    {(emergencyContacts.length > 0) ?
                                        <ul className='questions-list-view'>
                                            {emergencyContacts.map((person, i) => {
                                                const { address, city, state, zip, fullName, phone, relationship } = person

                                                return (
                                                    <div key={i}>
                                                        <div className='question-cell'>
                                                            <div className='section-label uppercase'>name</div>
                                                            <div className='section-label bold'>{fullName}</div>
                                                        </div>

                                                        <div className='question-cell'>
                                                            <div className='section-label uppercase'>relationship</div>
                                                            <div className='section-label bold'>{relationship}</div>
                                                        </div>

                                                        <div className='question-cell'>
                                                            <div className='section-label uppercase'>address</div>
                                                            <div className='section-label bold'>{`${address} ${city} ${state}, ${zip}`}</div>
                                                        </div>

                                                        <div className='question-cell'>
                                                            <div className='section-label uppercase'>contact phone</div>
                                                            <div className='section-label bold'>{phone}</div>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </ul>
                                        :
                                        <div className='section-hint-label'>There are no emergency contacts to display.</div>
                                    }
                                </div>
                            </div>
                        )
                    } else if (sectionTitle === 'Insurance Information') {
                        let arr = parseDataValue('person.insurances')

                        return (
                            <div className='section-frame'>
                                <div className='section-header'>{`health insurances (${arr.length})`}</div>

                                <ul className='questions-list-view'>
                                    {arr.map((insurance, i) => {
                                        const { carrier, groupNumber, memberNumber, primaryName, status } = insurance

                                        return (
                                            <div key={i}>
                                                <div className='question-cell'>
                                                    <div className='section-label uppercase'>insurance carrier</div>
                                                    <div className='section-label bold'>{carrier}</div>
                                                </div>

                                                <div className='question-cell'>
                                                    <div className='section-label uppercase'>member number</div>
                                                    <div className='section-label bold'>{memberNumber}</div>
                                                </div>

                                                <div className='question-cell'>
                                                    <div className='section-label uppercase'>group number</div>
                                                    <div className='section-label bold'>{groupNumber}</div>
                                                </div>

                                                <div className='question-cell'>
                                                    <div className='section-label uppercase'>primary card holder</div>
                                                    <div className='section-label bold'>{primaryName}</div>
                                                </div>

                                                <div className='question-cell'>
                                                    <div className='section-label uppercase'>status</div>
                                                    <div className='section-label bold'>{status}</div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </ul>
                            </div>
                        )
                    } else if (sectionTitle === 'Health History') {
                        let medications = parseDataValue('person.medications'),
                            issues = parseDataValue('person.issues'),
                            allergies = parseDataValue('person.allergies')

                        return (
                            <>
                                <div className='section-frame'>
                                    <div className='section-header'>{`medications (${medications.length})`}</div>

                                    {(medications.length > 0) ?
                                        <div className='section-table-view'>
                                            <div className='table-headers'>
                                                <div className='table-header name'>name</div>
                                                <div className='table-header' />
                                                <div className='table-header date'>date</div>
                                                <div className='table-header status'>status</div>
                                            </div>

                                            <ul className='section-list-view'>
                                                {medications.map((obj, i) => {
                                                    const { date, name, status } = obj

                                                    return (
                                                        <div className='section-cell' key={i}>
                                                            <div className='section-label'>{name}</div>
                                                            <div className='section-label middle-1' />
                                                            <div className='section-label middle-2'>{moment(date).format('MMM D, YYYY')}</div>
                                                            <div className='section-label status'>{status}</div>
                                                        </div>
                                                    )
                                                })}
                                            </ul>
                                        </div>
                                        :
                                        <div className='section-hint-label'>There are no medications to display.</div>
                                    }
                                </div>

                                <div className='section-frame'>
                                    <div className='section-header'>{`medical issues (${issues.length})`}</div>

                                    {(issues.length > 0) ?
                                        <div className='section-table-view'>
                                            <div className='table-headers'>
                                                <div className='table-header name'>name</div>
                                                <div className='table-header' />
                                                <div className='table-header date'>date</div>
                                                <div className='table-header status'>status</div>
                                            </div>

                                            <ul className='section-list-view'>
                                                {issues.map((obj, i) => {
                                                    const { date, name, status } = obj

                                                    return (
                                                        <div className='section-cell' key={i}>
                                                            <div className='section-label' style={{ flexBasis: '49%' }}>{name}</div>
                                                            <div className='section-label middle-2'>{moment(date).format('MMM D, YYYY')}</div>
                                                            <div className='section-label status'>{status}</div>
                                                        </div>
                                                    )
                                                })}
                                            </ul>
                                        </div>
                                        :
                                        <div className='section-hint-label'>There are no issues to display.</div>
                                    }
                                </div>

                                <div className='section-frame'>
                                    <div className='section-header'>{`allergies (${allergies.length})`}</div>

                                    {(allergies.length > 0) ?
                                        <div className='section-table-view'>
                                            <div className='table-headers'>
                                                <div className='table-header name'>name</div>
                                                <div className='table-header' />
                                                <div className='table-header date'>date</div>
                                                <div className='table-header status'>status</div>
                                            </div>

                                            <ul className='section-list-view'>
                                                {allergies.map((obj, i) => {
                                                    const { date, name, status } = obj

                                                    return (
                                                        <div className='section-cell' key={i}>
                                                            <div className='section-label'>{name}</div>
                                                            <div className='section-label middle-1' />
                                                            <div className='section-label middle-2'>{moment(date).format('MMM D, YYYY')}</div>
                                                            <div className='section-label status'>{status}</div>
                                                        </div>
                                                    )
                                                })}
                                            </ul>
                                        </div>
                                        :
                                        <div className='section-hint-label'>There are no allergies to display.</div>
                                    }
                                </div>
                            </>
                        )
                    } else if (sectionTitle === 'Family History') {
                        let arr = parseDataValue('person.family_history')

                        return (
                            <div className='section-frame'>
                                <div className='section-header'>family history</div>

                                {(arr.length > 0) ?
                                    <div className='section-table-view'>
                                        <div className='table-headers'>
                                            <div className='table-header name'>relationship</div>
                                            <div className='table-header name'>status</div>
                                            <div className='table-header date'>age</div>
                                            <div className='table-header status'>medical issues</div>
                                        </div>

                                        <ul className='section-list-view'>
                                            {arr.map((obj, i) => {
                                                const { alive, dob, issues, relationship } = obj

                                                return (
                                                    <div className='section-cell' key={i}>
                                                        <div className='section-label'>{_.startCase(relationship)}</div>
                                                        <div className='section-label middle-1'>{alive ? 'Yes' : 'No'}</div>
                                                        <div className='section-label middle-2'>{moment(dob).format('MMM D, YYYY')}</div>
                                                        <div className='section-label status'>{issues}</div>
                                                    </div>
                                                )
                                            })}
                                        </ul>
                                    </div>
                                    :
                                    <div className='section-hint-label'>There are no allergies to display.</div>
                                }
                            </div>
                        )
                    } else if (sectionTitle === 'Social History') {
                        let arr = parseDataValue('person.social_history')

                        return (
                            <div className='section-frame' style={{ borderBottom: 0 }}>
                                <div className='section-header'>social history</div>

                                {(arr.length > 0) ? 
                                    <ul className='questions-list-view'>
                                        {arr.map((obj, i) => {
                                            const { freq, status, type } = obj

                                            return (
                                                <div className='section-frame' style={{ flexDirection: 'column' }}>
                                                    <div className='section-header' style={{ fontSize: 18 }}>{type}</div>

                                                    <div className='question-cell'>
                                                        <div className='section-label uppercase'>status</div>
                                                        <div className='section-label bold'>{status ? 'yes' : 'no'}</div>
                                                    </div>

                                                    {freq ?
                                                        <div className='question-cell'>
                                                            <div className='section-label uppercase'>frequency</div>
                                                            <div className='section-label bold'>{freq}</div>
                                                        </div>
                                                        :
                                                        null
                                                    }
                                                </div>
                                            )
                                        })}
                                    </ul>
                                    :
                                    <div className='section-hint-label'>There are no social history to display.</div>
                                }
                            </div>
                        )
                    } else {
                        return (
                            <div key={i}>
                                <div className='section-frame row'>
                                    <div className='section-header' key={i}>{sectionTitle}</div>
                                </div>
                            </div>
                        )
                    }

                    // if (group.label === 'emergency_contact') (<EmergencyContact {...props} group={group} />)
                    // else if (group.label === 'insurance_info') (<Insurance {...props} group={group} />)
                    // else if (group.label === 'family_history') (<FamilyHistory {...props} group={group} />)
                    // else if (group.label === 'health_history') (<HealthHistory {...props} group={group} />)
                    // else if (group.label === 'social_history') (<SocialHistory {...props} group={group} />) 
                })}
            </ul>
        </div>
    )
}

export default HealthSnapPDF